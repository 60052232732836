/* Brand is centered on big desktop screens */
header {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    height: 200px;
    margin-bottom: 30px;
}
header [class*="col"] {
    position: relative;
    text-align: center;
    height: 200px;
}
header .siteName {
    position: relative;
    top: 94px;
    left: 0;

    font-size: 3.5em;
    color: white;
    word-wrap: break-word;
    hyphens: none;
    text-align: center;
}

header .companyName {
    position: relative;
    top: 94px;
    left: 0;

    display: block;
    font-size: 16px;
    color: white;
    word-wrap: break-word;
    hyphens: none;
    text-align: center;
}


/* md */
@media (max-width: 1199px) {
    header .siteName {
        top: 94px;
        font-size: 3.5em;
    }
}

/* xss */
@media only screen and (max-width: 595px) {
    header .siteName {
        top: 54px;
        line-height: 60px;
    }

    header .companyName {
        top: 54px;
    }
}
