form {
    margin-bottom: 30px;
    background-color: #ffffff !important;
    background-image: none;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

/* Remove rounded corners from bootstrap */
.form-group,
.input-group,
.input-group input,
.btn {
    border-radius: 0;
}

/* Tooltip styles */
.popover {
    border-radius:0;
}
.fa {
    padding-left:5px;
}


.width-100-percent .form-group,
.width-100-percent .input-group,
.width-100-percent .input-group input,
.width-100-percent .btn {
    width: 100%;
}



/* VIN field styles */
#vin {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    margin-bottom: 40px;
    border-radius: 0;
    inline-size: auto;
    height: auto;
}
#submit {
    box-shadow: 2px 3px 6px rgba(0, 0, 0, 0.16), 2px 3px 6px rgba(0, 0, 0, 0.23);
    margin-bottom: 40px;
}
button#submit {
    outline: 0 !important;  /* Remove the blue outline added by bootstrap */
}
#submit:focus {
    border-color: #318837;
}
#runVin {
    margin-bottom: 30px;
    background-color: #ffffff !important;
    background-image: none;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}


#logout {
    position: absolute;
    top: 0;
    left: 80%;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}
/* On phones, make sure the logout button is visible */
@media only screen and (max-width: 595px) {
    #logout {
        left:75%;
    }
}

.loading {
    background: url("../../../../public/spinner.gif") no-repeat center #3fad46;
    border-color: #3fad46;
    background-size: 30px 30px;
}

/* Custom Card styles */
.custom-card {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    border: 0;
    border-radius: 0;
}
.custom-card .card-header {
    background: #f5f5f5 none;
    text-transform: capitalize;
    border-radius: 0;
    font-size: 20px;
}
/* Remove some of bootstrap's table formatting */
.custom-card table {
    margin-bottom: 0;
    margin-top: -1px;
    width: 100%;
    table-layout: fixed
}
/* Adds a small gap on left & right side of cards for phones */
@media only screen and (max-width: 767px) {
    .custom-card {
        margin-left: 5px;
        margin-right: 5px;
    }
}


/* Result card styles */
.custom-card.result-card .card-body {
    background: #6979bc none;
    font-weight: bold;
    color: #fff;
}
.custom-card.result-card .card-body {
    padding: 0;
}
/* On phones, shrink the font size so text doesn't bleed out of the cards */
@media only screen and (max-width: 595px) {
    .result-card {
        font-size: 13px;
    }
}


/* Styles for color-coding odometer values */
.detected-text {
    font-weight: bold;
    color: red;
}
.none-detected-text {
    font-weight: bold;
    color: green;
}


/* This prevents WebKit browsers from turning input fields yellow while auto-filling */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
}


/* Error card styles */
.error-card .card-body .error-description  {
    font-size: 13px;
    text-align: left;
    display: inline-block;
}
.error-description p:last-child {
    margin-bottom: 0;
}
.error-card a {
    cursor: pointer;
}
.error-card .card-body {
    padding: 15px;
}
.error-card .card-body > .row {
    text-align: center;
}
.error-card .card-header {
    color: black;
    background-color: #fcf8e3;
}